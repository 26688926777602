// extracted by mini-css-extract-plugin
export var column = "BareMetalServicesOverview__column__HkdIi";
export var flex = "BareMetalServicesOverview__flex__qssii";
export var flexColumn = "BareMetalServicesOverview__flexColumn__LdKlK";
export var gap1 = "BareMetalServicesOverview__gap1___cfK2";
export var gap2 = "BareMetalServicesOverview__gap2__gYH0t";
export var gap3 = "BareMetalServicesOverview__gap3___b3s4";
export var gap4 = "BareMetalServicesOverview__gap4__InjFG";
export var gap5 = "BareMetalServicesOverview__gap5__CPq50";
export var row = "BareMetalServicesOverview__row__b_aav";
export var service = "BareMetalServicesOverview__service__btfBh";
export var serviceDetails = "BareMetalServicesOverview__serviceDetails__WBKUT";
export var servicesOverview = "BareMetalServicesOverview__servicesOverview__Rezay";