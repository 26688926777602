// extracted by mini-css-extract-plugin
export var bannerRow = "BareMetalGetStarted__bannerRow__HTUwb";
export var buttonColumn = "BareMetalGetStarted__buttonColumn__gsBEY";
export var buttonRequestEstimate = "BareMetalGetStarted__buttonRequestEstimate__j0r0H";
export var column = "BareMetalGetStarted__column__dBz31";
export var flex = "BareMetalGetStarted__flex__NmbBn";
export var flexColumn = "BareMetalGetStarted__flexColumn__m9ZoM";
export var gap1 = "BareMetalGetStarted__gap1__nl8LD";
export var gap2 = "BareMetalGetStarted__gap2__GYbp5";
export var gap3 = "BareMetalGetStarted__gap3__HBmwa";
export var gap4 = "BareMetalGetStarted__gap4__B8CVD";
export var gap5 = "BareMetalGetStarted__gap5__AaAj4";
export var row = "BareMetalGetStarted__row__Wno3X";
export var title = "BareMetalGetStarted__title__sQSYt";
export var wrapper = "BareMetalGetStarted__wrapper__XJfFk";
export var wrapperInner = "BareMetalGetStarted__wrapperInner__fLlRy";