// extracted by mini-css-extract-plugin
export var button = "JumbotronProvider__button__gCaeX";
export var buttonLink = "JumbotronProvider__buttonLink__js8YR";
export var column = "JumbotronProvider__column__hWBHg";
export var flex = "JumbotronProvider__flex__gu3Kf";
export var flexColumn = "JumbotronProvider__flexColumn__bV2pp";
export var gap1 = "JumbotronProvider__gap1__wGSrQ";
export var gap2 = "JumbotronProvider__gap2__B9zOp";
export var gap3 = "JumbotronProvider__gap3__w3p9H";
export var gap4 = "JumbotronProvider__gap4__WygiH";
export var gap5 = "JumbotronProvider__gap5__oQAQ5";
export var image = "JumbotronProvider__image__SCQzD";
export var row = "JumbotronProvider__row__hH24S";
export var text = "JumbotronProvider__text__eKOpj";
export var wrapper = "JumbotronProvider__wrapper__ntYob";