// extracted by mini-css-extract-plugin
export var column = "WhatIsBareMetalNew__column__NkFiA";
export var flex = "WhatIsBareMetalNew__flex__XwmPF";
export var flexColumn = "WhatIsBareMetalNew__flexColumn__qmwf9";
export var gap1 = "WhatIsBareMetalNew__gap1__tSOSC";
export var gap2 = "WhatIsBareMetalNew__gap2__ZF7aC";
export var gap3 = "WhatIsBareMetalNew__gap3__Im8Ia";
export var gap4 = "WhatIsBareMetalNew__gap4__M6a7j";
export var gap5 = "WhatIsBareMetalNew__gap5__EC9H4";
export var header = "WhatIsBareMetalNew__header__QhNO8";
export var row = "WhatIsBareMetalNew__row__v6Fm2";
export var sections = "WhatIsBareMetalNew__sections__ZIYYL";