// extracted by mini-css-extract-plugin
export var column = "BareMetalHighlight__column__tFqdV";
export var flex = "BareMetalHighlight__flex__LiF7u";
export var flexColumn = "BareMetalHighlight__flexColumn__uVhTQ";
export var gap1 = "BareMetalHighlight__gap1__ra1L5";
export var gap2 = "BareMetalHighlight__gap2__vd73S";
export var gap3 = "BareMetalHighlight__gap3__volqA";
export var gap4 = "BareMetalHighlight__gap4__ifywl";
export var gap5 = "BareMetalHighlight__gap5__nrKJC";
export var reason = "BareMetalHighlight__reason__LLJ92";
export var reasonDescription = "BareMetalHighlight__reasonDescription__KL0lj";
export var reasonTitle = "BareMetalHighlight__reasonTitle__iMDTl";
export var row = "BareMetalHighlight__row__rSy8M";
export var svgIcon = "BareMetalHighlight__svgIcon__rLA1r";