// extracted by mini-css-extract-plugin
export var answerWithLink = "BareMetalFaqs__answerWithLink__W6NpB";
export var column = "BareMetalFaqs__column__qfUy9";
export var flex = "BareMetalFaqs__flex__tqM_k";
export var flexColumn = "BareMetalFaqs__flexColumn__KkPaF";
export var gap1 = "BareMetalFaqs__gap1__fUiXW";
export var gap2 = "BareMetalFaqs__gap2__KFMFa";
export var gap3 = "BareMetalFaqs__gap3__uBMAW";
export var gap4 = "BareMetalFaqs__gap4__u8mUY";
export var gap5 = "BareMetalFaqs__gap5__bqGZ2";
export var heading = "BareMetalFaqs__heading__dnJ_f";
export var row = "BareMetalFaqs__row___yzAV";
export var wrapper = "BareMetalFaqs__wrapper___TDbc";