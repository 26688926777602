// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../../../styleguide/components/Paragraph/P.res.js";
import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as WhatIsBareMetalSection from "./components/WhatIsBareMetalSection.res.js";
import * as WhatIsBareMetalNewScss from "./WhatIsBareMetalNew.scss";
import BaremetalOsSvg from "./assets/baremetal-os.svg";
import BaremetalHybridSvg from "./assets/baremetal-hybrid.svg";
import BaremetalHighSpeedSvg from "./assets/baremetal-high-speed.svg";

var css = WhatIsBareMetalNewScss;

var dedicatedServers = BaremetalHighSpeedSvg;

var baremetalHybridSvg = BaremetalHybridSvg;

var baremetalOsSvg = BaremetalOsSvg;

function WhatIsBareMetalNew(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "What is Bare Metal?"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Bare metal is a server without any pre-configured software that is dedicated to a single tenant. The server can be optimized by the tenant for its specific needs and purposes. Bare metal servers are also known as managed dedicated servers. The operating system for the bare metal server is installed directly which helps optimize performance."
                            })
                      ],
                      className: css.header
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(WhatIsBareMetalSection.make, {
                              text: "Single tenancy means your dedicated server is optimized for your computing needs alone — no \"noisy neighbors\" can impact performance, reliability or security.",
                              image: dedicatedServers,
                              title: "Dedicated Servers",
                              direction: "Left"
                            }),
                        JsxRuntime.jsx(WhatIsBareMetalSection.make, {
                              text: "Directly connect to your servers in other regions and public clouds for a completely interconnected solution. Make additional connections to AWS, Microsoft Azure and Google Cloud and more.",
                              image: baremetalHybridSvg,
                              title: "Hybrid Cloud Solutions",
                              direction: "Right"
                            }),
                        JsxRuntime.jsx(WhatIsBareMetalSection.make, {
                              text: "Choose from a range of operating systems including CentOS, Debian, Ubuntu, ESXi, Windows or a custom configuration. Windows Server licenses available starting from $20/server.",
                              image: baremetalOsSvg,
                              title: "Multiple Operating Systems",
                              direction: "Left"
                            })
                      ],
                      className: css.sections
                    })
              ]
            });
}

var make = WhatIsBareMetalNew;

export {
  css ,
  dedicatedServers ,
  baremetalHybridSvg ,
  baremetalOsSvg ,
  make ,
}
/* css Not a pure module */
