// extracted by mini-css-extract-plugin
export var column = "BareMetalGetStartedButton__column__xLnlj";
export var ctaButton = "BareMetalGetStartedButton__ctaButton__VZkr9";
export var flex = "BareMetalGetStartedButton__flex__cOZCd";
export var flexColumn = "BareMetalGetStartedButton__flexColumn__jA8R3";
export var gap1 = "BareMetalGetStartedButton__gap1__z7kuq";
export var gap2 = "BareMetalGetStartedButton__gap2__kDd9i";
export var gap3 = "BareMetalGetStartedButton__gap3__qKCGi";
export var gap4 = "BareMetalGetStartedButton__gap4__WoF9g";
export var gap5 = "BareMetalGetStartedButton__gap5__tsOfg";
export var popoverBody = "BareMetalGetStartedButton__popoverBody__Yxo6p";
export var popoverBodyLink = "BareMetalGetStartedButton__popoverBodyLink__lQSWn";
export var popoverBodyLinkIcon = "BareMetalGetStartedButton__popoverBodyLinkIcon__VqKEc";
export var row = "BareMetalGetStartedButton__row___YzrK";