// extracted by mini-css-extract-plugin
export var column = "BareMetalHighlights__column__cARHc";
export var flex = "BareMetalHighlights__flex__ew33v";
export var flexColumn = "BareMetalHighlights__flexColumn__oTGi1";
export var gap1 = "BareMetalHighlights__gap1__VPNkR";
export var gap2 = "BareMetalHighlights__gap2__yMrmt";
export var gap3 = "BareMetalHighlights__gap3__KiF3_";
export var gap4 = "BareMetalHighlights__gap4__i0234";
export var gap5 = "BareMetalHighlights__gap5__Lpvvw";
export var highlights = "BareMetalHighlights__highlights__ZWTRu";
export var row = "BareMetalHighlights__row__Wqfub";