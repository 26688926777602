// extracted by mini-css-extract-plugin
export var column = "JumbotronBareMetalIndex__column__x8h6u";
export var flex = "JumbotronBareMetalIndex__flex__A9piV";
export var flexColumn = "JumbotronBareMetalIndex__flexColumn__tucrQ";
export var gap1 = "JumbotronBareMetalIndex__gap1__su4sU";
export var gap2 = "JumbotronBareMetalIndex__gap2__T1uXS";
export var gap3 = "JumbotronBareMetalIndex__gap3__Qo5RJ";
export var gap4 = "JumbotronBareMetalIndex__gap4__wy46A";
export var gap5 = "JumbotronBareMetalIndex__gap5__zYLZK";
export var heading = "JumbotronBareMetalIndex__heading__EBjYs";
export var providers = "JumbotronBareMetalIndex__providers__TykH7";
export var row = "JumbotronBareMetalIndex__row__rDGxd";
export var subheading = "JumbotronBareMetalIndex__subheading__EfoWr";
export var wrapper = "JumbotronBareMetalIndex__wrapper__lA3tN";