// extracted by mini-css-extract-plugin
export var column = "JumbotronProviders__column__SqiH1";
export var flex = "JumbotronProviders__flex__dInbX";
export var flexColumn = "JumbotronProviders__flexColumn__QLcO0";
export var gap1 = "JumbotronProviders__gap1__a5ubE";
export var gap2 = "JumbotronProviders__gap2__NnOlQ";
export var gap3 = "JumbotronProviders__gap3__XGE9m";
export var gap4 = "JumbotronProviders__gap4__WP5ps";
export var gap5 = "JumbotronProviders__gap5__X4gLt";
export var horizontalScroll = "JumbotronProviders__horizontalScroll__GMCFI";
export var providers = "JumbotronProviders__providers__wvaqq";
export var row = "JumbotronProviders__row__BrZxi";