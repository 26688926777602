// extracted by mini-css-extract-plugin
export var column = "WhatIsBareMetalSection__column__vUd6a";
export var description = "WhatIsBareMetalSection__description__ISMKc";
export var flex = "WhatIsBareMetalSection__flex__v500j";
export var flexColumn = "WhatIsBareMetalSection__flexColumn__E2WrN";
export var gap1 = "WhatIsBareMetalSection__gap1__AbSxs";
export var gap2 = "WhatIsBareMetalSection__gap2__ozLbD";
export var gap3 = "WhatIsBareMetalSection__gap3__CWKpo";
export var gap4 = "WhatIsBareMetalSection__gap4__ykQ2B";
export var gap5 = "WhatIsBareMetalSection__gap5__Wu1UJ";
export var image = "WhatIsBareMetalSection__image__vSbcf";
export var left = "WhatIsBareMetalSection__left__XU2eE";
export var right = "WhatIsBareMetalSection__right__up4jY";
export var row = "WhatIsBareMetalSection__row__DMtcI";
export var section = "WhatIsBareMetalSection__section__TbDVJ";
export var text = "WhatIsBareMetalSection__text__mvtKW";