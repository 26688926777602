// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../../../styleguide/components/Paragraph/P.res.js";
import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BareMetalServicesOverviewScss from "./BareMetalServicesOverview.scss";

var css = BareMetalServicesOverviewScss;

function BareMetalServicesOverview(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "Servers Wherever and Whenever You Need Them"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(P.make, {
                                      children: "When it comes to getting ahead of your competition and launching into new markets quickly and effectively, speed is key. Grow or reduce server capacity with no fixed costs or upfront obligations with the Datacenters.com's IaaS Bare Metal platform."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Seamlessly choose to add or remove servers whenever you need to, no matter the region. A single click is all it takes to increase your market reach and conquer new frontiers. Add or remove servers as needed to meet customer demand, handle peak load times, or react to international markets in an instant."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Bridge the gap between regions with ease by connecting cost effective servers both in private and public clouds. Eliminate the hassle of integrating multiple platformsand craft a unique and bespoke solution specifically designed for your business."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Whether you're a startup looking to explore uncharted waters or an established player hoping to stretch even farther across the globe, you can access emerging markets like China, India, and South America with our Bare Metal IaaS model."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "Fast, Scalable Infrastructure as a service"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(P.make, {
                                      children: "With complete control over your server infrastructure, you have the freedom to seize new business opportunities no matter where they are in the world. Never worry about physical infrastructure restraints ever again—enter regions you've never dipped into at any given time and customize your servers to maximize your business potential abroad. Expand your overseas sales faster than ever with cost-efficiencies that keep you one step ahead of the game at all times."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "By being able to scale up or down without difficulty, you too can join the ranks of customers who are experiencing growth on a global scale with our advanced cloud technology. With Datacenters.com, performance and flexibility are effortlessly in sync like never before."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "Solutions Tailor Made to your Business"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(P.make, {
                                      children: "Take back control and customize your servers to specifically fit your business's requirements. With the Datacenters.com bare metal IaaS RFP, you won't have to hunt for the best provider: we place them right at your fingertips."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Don't waste your time, effort and vital resources shopping around between different providers to find an Infrastructure as a Service (IaaS) solution - all you need to do is customize your IaaS bare metal server and in just 2 minutes you can submit your requirements to industry leading solution providers."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "By carefully selecting features that are specially tailored towards meeting your specified requirements, all the guesswork of which cloud infrastructure platform will serve you best goes out the window. You can easily customize IaaS bare metal servers to get the exact solution your business needs, without having to go through endless hours of comparison."
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "With just a few easy steps you can take the hassle out of finding your perfect bare metal server solution. Discover the freedom of being able to choose from several great options tailored to fit your needs while consolidating choices into one neat platform. Save time and energy so you can get back to focusing on things that matter most for your business."
                                    })
                              ],
                              className: css.serviceDetails
                            })
                      ],
                      className: css.service
                    })
              ],
              className: css.servicesOverview
            });
}

var make = BareMetalServicesOverview;

export {
  css ,
  make ,
}
/* css Not a pure module */
