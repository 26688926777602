// extracted by mini-css-extract-plugin
export var column = "ServicesSeparatorBlock__column__s3PJ3";
export var flex = "ServicesSeparatorBlock__flex__OtUDD";
export var flexColumn = "ServicesSeparatorBlock__flexColumn__kuLt_";
export var gap1 = "ServicesSeparatorBlock__gap1__LAzQO";
export var gap2 = "ServicesSeparatorBlock__gap2__XIJnP";
export var gap3 = "ServicesSeparatorBlock__gap3__BqMSo";
export var gap4 = "ServicesSeparatorBlock__gap4__GQcjG";
export var gap5 = "ServicesSeparatorBlock__gap5__QTakq";
export var row = "ServicesSeparatorBlock__row__x_PcN";
export var separator = "ServicesSeparatorBlock__separator__ydfuT";
export var separatorBox = "ServicesSeparatorBlock__separatorBox__i4BRJ";
export var separatorWithTitle = "ServicesSeparatorBlock__separatorWithTitle__t_qle";