// extracted by mini-css-extract-plugin
export var blogPost = "BlogPostsSidebarSection__blogPost__lZET8";
export var blogPostDate = "BlogPostsSidebarSection__blogPostDate__RDKFP";
export var blogPostTitle = "BlogPostsSidebarSection__blogPostTitle__PosIa";
export var blogPosts = "BlogPostsSidebarSection__blogPosts__H2lGP";
export var blogPostsLink = "BlogPostsSidebarSection__blogPostsLink__egx3d";
export var column = "BlogPostsSidebarSection__column__wTTj_";
export var flex = "BlogPostsSidebarSection__flex__ufMHJ";
export var flexColumn = "BlogPostsSidebarSection__flexColumn__Qime_";
export var gap1 = "BlogPostsSidebarSection__gap1__edAMK";
export var gap2 = "BlogPostsSidebarSection__gap2__VeKsJ";
export var gap3 = "BlogPostsSidebarSection__gap3__cG8hO";
export var gap4 = "BlogPostsSidebarSection__gap4__kOrD7";
export var gap5 = "BlogPostsSidebarSection__gap5__HMYOo";
export var row = "BlogPostsSidebarSection__row__xlAqH";
export var wrapper = "BlogPostsSidebarSection__wrapper__uc5B6";